<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel title="List Kode Bayar" class="panel panel-success">

            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <div class="pull-right">
                                <b-button variant="primary" to="/menu/kode-bayar/buat">
                                    <i class="fa fa-plus pr-1"></i> Tambah Kode Bayar
                                </b-button>
                            </div>
                            <h6 class="mb-0">Tools</h6>
                        </template>

                        <!-- FILTER 01 -->
                        <b-card-body class="p-0 m-0">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter By"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="filterBy"
                                                :options="fieldOptions"
                                                @change="changeFilterBy()"
                                            >
                                                <option slot="first" :value="'all'"
                                                    >All Data</option
                                                >
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter"
                                        class="mb-0"
                                        description="Type to Search or Click Clear to Stop Searching "
                                    >
                                        <b-input-group size="md">
                                            <b-form-input
                                                v-model="filter"
                                                @keyup.enter="testFunc(filter)"
                                                placeholder="Type to Search"
                                                debounce="500"
                                            />
                                            <b-input-group-append>
                                                <b-btn
                                                    :disabled="!filter"
                                                    @click="filter = ''"
                                                    variant="info"
                                                    >Clear</b-btn
                                                >
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Sort"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="sortBy"
                                                :options="fieldOptions"
                                            >
                                                <option slot="first" :value="null">-- none --</option>
                                            </b-form-select>
                                            <b-form-select
                                                :disabled="!sortBy"
                                                v-model="sortDesc"
                                                slot="append"
                                            >
                                                <option :value="false">Asc</option>
                                                <option :value="true">Desc</option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <hr class="my-2">
                            <b-row>
                                <b-col md="6" v-role:any="'Administrator|Maintenance'">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Perangkat PD"
                                        class="mb-1"
                                    >
                                        <v-select
                                            label="nama"
                                            :reduce="nama => nama.id"
                                            :options="PemerintahDaerah.perangkat"
                                            placeholder="-Pilih Perangkat-"
                                            v-model="perangkatpd"
                                            @input="inputPerangkat"
                                        ></v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" v-role:any="'Administrator|Maintenance'">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Periode SPTRD"
                                        class="mb-1"
                                    >
                                        <b-row>
                                            <b-col md="8">
                                                <v-select
                                                    label="nama"
                                                    :options="bulan"
                                                    :reduce="nama => nama.id"
                                                    placeholder="-Pilih Bulan-"
                                                ></v-select>
                                            </b-col>
                                            <b-col md="4">
                                                <v-select
                                                    :options="tahunOptions"
                                                    placeholder="-Pilih Tahun-"
                                                ></v-select>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </b-col>
                                <b-col md="9">
                                    <b-form-group
                                        :label-cols="2"
                                        label="Status Bayar"
                                        class="mb-1"
                                    >
                                        <b-form-radio-group
                                            name="radio-sub-component"
                                            v-model="selected"
                                            class="pt-2 pb-0"
                                        >
                                            <b-form-radio @change="filterStatus('all')" value="all">
                                                <span class="font-weight-bold label label-warning">
                                                    Semua Status
                                                </span>
                                            </b-form-radio>
                                            <b-form-radio
                                                v-for="(item,
                                                index) in [
                                                    {
                                                        id: 1,
                                                        nama: 'Sudah Bayar',
                                                        warna: 'success'
                                                    },
                                                    {
                                                        id: 0,
                                                        nama: 'Belum Bayar',
                                                        warna: 'danger'
                                                    }
                                                ]"
                                                :key="index"
                                                @change="filterStatus(item.id)"
                                                :value="item.id"
                                            >
                                                <span
                                                    :class="
                                                        `font-weight-bold label label-${item.warna}`
                                                    "
                                                >
                                                    {{ item.nama }}
                                                </span>
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <!-- end FILTER 01 -->
                        
                        <!--
                        <div class="pull-right mx-0 my-0">
                            <b-button variant="primary" to="/menu/sptrd/pendaftaran">
                                <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                            </b-button>
                        </div> -->
                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="reload">
                                    <i class="fa fa-sync-alt pr-1"></i> Refresh
                                </b-button>
                                <!-- <b-button class="ml-2" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button> -->
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->

            <hr class="my-2">
            
            <!-- Data Table & Pagination -->
            <b-table
                show-empty
                small
                id="masTable"
                ref="masTable"
                head-variant="light"
                :hover="true"
                :busy.sync="isBusy"
                :items="myGetData"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive
                bordered
            >
                <!-- PENGATURAN KOLOM 
                -------------------------------------------------- -->
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- ... -->
                <template v-slot:table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                            width: field.key === 'actions' ? '105px' : '',
                        }"
                    />
                </template>
                <template #head()="data">
                    <div class="" v-html="data.label"></div>
                </template>
                <!-- ... -->
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <!-- end PENGATURAN KOLOM
                -------------------------------------------------- -->

                <template #cell(no_ssr)="data">
                    {{ data.item.no_ssr }}<br>
                    {{ data.item.tgl_ssr }}
                </template>
                <template #cell(no_sts)="data">
                    {{ data.item.no_sts }}<br>
                    {{ data.item.tgl_sts }}<br>
                    <div v-if="new Date(data.item.masa_aktif) > new Date()" class="badge badge-success" style="font-size: 0.7rem">
                        Masa Aktif: <b>{{ data.item.masa_aktif }}</b>
                    </div>
                    <div v-if="new Date(data.item.masa_aktif) < new Date()" class="badge badge-danger" style="font-size: 0.7rem">
                        Masa Aktif: <b>{{ data.item.masa_aktif }}</b>
                    </div>
                </template>
                <template #cell(status_bayar)="data">
                    <div class="text-center">
                        <div
                            v-if="data.item.status_bayar === 0"
                            class="label label-danger">
                            Belum Bayar
                        </div>
                        <div v-else class="label label-success">
                            Sudah Bayar
                        </div>
                    </div>
                </template>
                <template #cell(spt)="data">
                    <div v-if="data.item.spt">
                        {{ data.item.spt.no_spt }}<br>
                        {{ data.item.spt.tgl_spt }}
                    </div>
                </template>

                <template #cell(npwrd)="data">
                    <div v-if="data.item.spt.npwrd" style="width: 150px">
                        {{ data.item.spt.npwrd.no_pokok }}<br>
                        {{ data.item.spt.npwrd.nama }}
                    </div>
                </template>

                <template #cell(nilai_terutang)="data">
                    {{ data.item.nilai_terutang
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    }}
                </template>

                <template #cell(perangkat_pd)="data">
                    <div v-if="data.item.spt" style="width: 200px">
                        {{ data.item.spt.perangkat_pd.nama }}<br>
                        {{ data.item.spt.unit_pd.nama }}
                    </div>
                </template>

                <template v-slot:cell(actions)="data">
                    <b-dropdown 
                        split 
                        class="d-flex align-item-center" 
                        size="sm" 
                        variant="info">
                        <template #button-content>
                            <i class="fa fa-cogs pr-1"></i> Aksi
                        </template>
                        <!-- <b-dropdown-item @click="view(data.item.id)"
                            ><i class="fa fa-eye"></i> Lihat</b-dropdown-item
                        > -->
                        <b-dropdown-item
                            @click="editButton(data.item.id)"
                            disabled
                            ><i class="fa fa-edit"></i> Edit</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="swalNotification('error', data.item.id)"
                            disabled
                            ><i class="fa fa-trash-alt"></i>
                            Hapus</b-dropdown-item
                        >
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="showPdf(data.item.no_sts)"
                            ><i class="fa fa-file-pdf"></i> Kode
                            Bayar</b-dropdown-item
                        >
                    </b-dropdown>
                </template>
            </b-table>
            <!-- end of table -->

            <div class="divider"></div>

            <!-- pagination section -->
            <b-row>
                <div class="divider"></div>
                <b-col md="6" class="my-0">
                    <b-form-group :label-cols="2" label="Per page" class="mb-0">
                        <b-form inline>
                            <b-form-select
                                :options="pageOptions"
                                style="width: 100px;"
                                v-model="perPage"
                            />
                            <label class="ml-1 mr-1">/</label>
                            <b-form-input
                                :disabled="true"
                                v-model="totalRows"
                                style="width: 100px;"
                                class="text-right"
                            />
                            <label class="ml-1">Rows</label>
                        </b-form>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-0">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="md"
                        class="my-0 "
                    ></b-pagination>
                </b-col>
            </b-row>
            <!-- end pagination section -->
            <!-- end of Table -->
        </panel>
        <!-- Modal Data --->
        <b-modal
            title="Detail Kode Bayar"
            v-model="modal.showModalForm"
            id="modal"
            hide-footer
            no-close-on-backdrop
            size="lg"
        >
            <b-row>
                <b-col>
                    <div>
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >Perangkat</label
                        >
                        <h5>
                        </h5>
                    </div>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >Unit</label
                        >
                        <h5>Badan Lingkungan Hidup</h5>
                    </div>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >NPWRD</label
                        >
                        <h5>1029484848</h5>
                        <h5>PT UDAH AH</h5>
                    </div>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >No./Tgl. SPTRD</label
                        >
                        <h5>
                            <div v-if="kodeBayar">
                                {{ kodeBayar.spt.no_spt }}
                            </div>
                        </h5>
                        <h5>
                            <div  v-if="kodeBayar">
                                {{ kodeBayar.spt.tgl_spt}}
                            </div>
                        </h5>
                    </div>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >No./Tgl. SSRD</label
                        >
                        <h5>
                            <div  v-if="kodeBayar">
                                {{ kodeBayar.no_ssr }}
                            </div>
                        </h5>
                        <h5>
                            <div v-if="kodeBayar">
                                {{ kodeBayar.tgl_ssr }}
                            </div>
                        </h5>
                    </div>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >No./Tgl. STSRD</label
                        >
                        <h5>
                            <div  v-if="kodeBayar">
                                {{ kodeBayar.no_sts }}
                            </div>
                        </h5>
                        <h5>
                            <div v-if="kodeBayar">
                                {{ kodeBayar.tgl_sts }}
                            </div>
                        </h5>
                    </div>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >Masa Retribusi</label
                        >
                        <h5>
                            <div v-if="kodeBayar">
                                {{ kodeBayar.tahun }}/{{ kodeBayar.masa}}
                            </div>
                        </h5>
                    </div>
                </b-col>
                <b-col>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >Masa Aktif</label
                        >
                        <h5>
                            <div v-if="kodeBayar">
                                {{ kodeBayar.masa_aktif }}
                            </div>
                        </h5>
                    </div>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >Penerima</label
                        >
                        <h5>
                            <div v-if="kodeBayar">
                                <div v-if="kodeBayar.penerima">
                                    {{ kodeBayar.penerima }}
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </div>
                        </h5>
                    </div>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >Penyetor</label
                        >
                        <h5>
                            <div v-if="kodeBayar">
                                <div v-if="kodeBayar.penyetor">
                                    {{ kodeBayar.penyetor }}
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </div>
                        </h5>
                    </div>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >Metode Bayar</label
                        >
                        <h5>
                            <div v-if="kodeBayar">
                                <div v-if="kodeBayar.metode_bayar">
                                    {{ kodeBayar.metode_bayar }}
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </div>
                        </h5>
                    </div>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >Status Bayar</label
                        >
                        <h5>
                            <div v-if="kodeBayar">  
                                <div v-if="kodeBayar.status_bayar === 0">
                                    Belum Bayar
                                </div>
                                <div v-else>
                                    Sudah Bayar
                                </div>
                            </div>
                        </h5>
                        <h5>
                            <div v-if="kodeBayar">
                                {{ kodeBayar.bayar_at }}
                            </div>
                        </h5>
                    </div>
                    <div class="my-3">
                        <label
                            for="perangkat"
                            class="text-success font-weight-bold mb-1"
                            >Biaya Retribusi</label
                        >
                        <h3>
                            <div v-if="kodeBayar">
                                Rp. {{ kodeBayar.nilai_terutang.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                            </div>
                        </h3>
                    </div>
                </b-col>
            </b-row>
            <div class="ml-2">
                <label for="" class="text-success font-weight-bold">Keterangan</label>
                <div v-if="kodeBayar">
                    <b-form-textarea
                        id="textarea-no-resize"
                        placeholder="Masukkan Keterangan"
                        rows="6"
                        v-model="kodeBayar.keterangan"
                        no-resize
                        disabled
                    ></b-form-textarea>
                </div>
            </div>
        </b-modal>
        <!-- End of Modal -->
        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "../../../components/npwr/HRetribusi.vue";
import axios from "axios";
import { mapState } from "vuex";
import kodeBayarMixin from "@/helper/api/kodeBayar.js";
import hapusStorage from "@/helper/hapusLocalStore"

export default {
    mixins: [kodeBayarMixin, hapusStorage],
    components: { HRetribusi },
    data() {
        return {
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,

            // fields
            fields: [
                {
                    key: "index",
                    label: "No.",
                    tdClass: "text-right",
                },
                {
                    key: "perangkat_pd",
                    label: "PERANGKAT & UNIT",
                    sortable: true,
                },
                {
                    key: "npwrd",
                    label: "NPWRD",
                    sortable: true,
                },
                {
                    key: "spt",
                    label: "NO./TGL. SPTRD",
                    sortable: true,
                },
                {
                    key: "no_sts",
                    label: "KODE BAYAR",
                    sortable: true,
                },
                {
                    key: "metode_bayar",
                    label: "METODE BAYAR",
                    sortable: true,
                },
                {
                    key: "nilai_terutang",
                    label: "BIAYA RETRIBUSI (Rp.)",
                    sortable: false,
                    tdClass: "text-right",
                },
                {
                    key: "status_bayar",
                    label: "STATUS BAYAR",
                    sortable: false,
                },
                {
                    key: "actions",
                    label: "Opsi",
                },
            ],
            modal: {
                showModalForm: false,
            },
            form: new Form({
                spt_id: "",
                surat_id: "",
                keterangan: "",
                no_sts: "",
                no_ssr: "",
            }),
            showLoader: false,
            isLoading: false,
            pdfUrl: "",
            isLoading: false,
            bulan: [
                {
                    id: "01",
                    nama: "Januari"
                },
                {
                    id: "02",
                    nama: "Februari"
                },
                {
                    id: "03",
                    nama: "Maret"
                },
                {
                    id: "04",
                    nama: "April"
                },
                {
                    id: "05",
                    nama: "Mei"
                },
                {
                    id: "06",
                    nama: "Juni"
                },
                {
                    id: "07",
                    nama: "Juli"
                },
                {
                    id: "08",
                    nama: "Agustus"
                },
                {
                    id: "09",
                    nama: "September"
                },
                {
                    id: "10",
                    nama: "Oktober"
                },
                {
                    id: "11",
                    nama: "November"
                },
                {
                    id: "12",
                    nama: "Desember"
                }
            ],
            tahunOptions: [],
            selected: null,
            perangkatpd: null,
            statusspt: 'all'
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
        ...mapState(["Sptrd", "StatusAplikasi", "PemerintahDaerah"]),
    },
    mounted() {
        this.$store.dispatch("Sptrd/sptStore");
        this.$store.dispatch("Sptrd/suratStore");
        this.$store.dispatch("PemerintahDaerah/getPerangkat");
        this.getTahun()
    },
    methods: {
        inputPerangkat: function () {
            this.reload()
        },
        // data table
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/transaksi/kode-bayar", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                    status_bayar: this.statusspt,
                    perangkatpd: this.perangkatpd
                },
            });
            return promise
                .then((response) => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch((error) => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete("/api/manajemen/tarif/" + id)
                        .then((response) => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 1800,
                            });
                            this.reload();
                        });
                }
            });
        },
        editButton(index) {
            this.$router.push({ name: "EditKodeBayar", params: { id: index } });
        },

        // show modal
        view(id) {
            this.$root.$emit("bv::show::modal", "modal");
            this.modal.showModalForm == true;
            // kode bayar mixin
            this.detailKodeBayar(id);
        },

        showPdf(id) {
            this.getPdf(id);
        },
        // close modal
        closeModal() {
            this.$root.$emit("bv::hide::modal", "modal-1");
            this.showModalData = false;
            this.pdfUrl = null;
        },
        // view pdf
        getPdf(id) {
            this.showLoader = true
            axios
                .get("/api/view-pdf/surat/kode-bayar/by-sts/" + id, {
                    responseType: "arraybuffer"
                })
                .then((response) => {
                    this.showLoader = false
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, '_blank')
                })
                .catch((error) => {
                    this.showLoader = false
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        getTahun() {
            const now = new Date();
            this.tahunOptions.push(now.getFullYear() - 1);
            this.tahunOptions.push(now.getFullYear());
        },
        filterStatus(value) {
            this.statusspt = value;
            this.reload();
        },
    },
};
</script>
