import axios from 'axios'
import hapusStorage from '@/helper/hapusLocalStore'

export const kodeBayarMixin = {
    mixins: [hapusStorage],
    data () {
        return {
            kodeBayar: null
        }
    },
    methods: {
        detailKodeBayar (id) {
            axios.get("/api/transaksi/kode-bayar/" + id).then(response => {
                const items = response.data.data
                this.kodeBayar = items
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        }
    }
}

export default kodeBayarMixin